<template>
  <div class="flex flex-col md:flex-row my-6 md:pt-14 md:mb-40 xl:px-36 md:max-h-650">
    <div class="relative h-auto flex-1">
      <img class="w-full md:w-auto h-full mx-0 md:ml-auto md:mr-0" src="@/assets/me.jpg" alt="Roman Parubochyi Photography">
      <div class="arrow-down">
        <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto animate-bounce h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
    <div class="px-7 md:ml-10 flex-1 md:flex md:flex-col md:justify-between md:items-start">
      <div class="font-cg">
        <h2 class="text-xl mb-2 mt-4 md:mt-0 tracking-widest">
          Привіт! Мене звати
        </h2>
        <h1 class="text-2xl md:text-4xl text-gray-900 font-medium mb-6 md:mb-12 tracking-widest">Роман Парубочий</h1>
        <p class="text-13 md:text-xl leading-relaxed text-gray-900 tracking-widest md:w-3/4 mb-10">
          Вже більше 5 років я створюю спогади ваших теплих історій та неповторних моментів.<br>
          Щирі емоції - це те, що надихає мене творити і розвиватись.<br>
          Фотографую щасливі сім’ї, закоханих людей та портрети і завжди відкритий для нових неординарних проектів та крутих ідей.<br>
          Люблю теплі кадри і обожнюю те, чим займаюсь.<br>
          Буду радий створити спогади вашої унікальної історії.
        </p>
      </div>
        
      <router-link to="/contacts" class="mt-4 text-white bg-black py-3 px-5 uppercase text-xs hover:text-black border border-black hover:bg-white transition delay-200 ease-in-out">Contact Me</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about'
}
</script>

<style lang="postcss" scoped>
.arrow-down {
  @apply absolute bottom-5 left-0 right-0 text-white opacity-70 md:hidden;
}
</style>